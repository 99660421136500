/**
 * 
 */

import axios from 'axios'



const client	= axios.create({

	// baseURL	: 'https://randomapi.frinbr.com/datas/days/',
	// baseURL	: 'https://randomapi.frinbr.com/day_json.php?day=',

	baseURL	: process.env.NODE_ENV == 'development' ? 'https://ttfl.frinbr.com/api/datas/days/' : 'https://randomapi.frinbr.com/day_json.php?day=',
	json	: true,
	// `headers` are custom headers to be sent
	headers	: {
		'X-Requested-With'	: 'XMLHttpRequest',
		'Content-Type'	: 'application/json',
	},
	crossdomain	: true,
	withCredentials: false,

})

client.interceptors.request.use( config => {

	return config

})

client.interceptors.response.use( response => {

	return response

})
// , function( error ) {

// 	console.log( error )
// 	return false

// })

export default {

	async execute( method, resource, data, params ) {
		
		return client({
			method	: method,
			url	: resource,
			data	: data,
			params	: params,
		}).then(req => {
			return req.data
		})

	},

	get ( day_file ) {

		return this.execute( 'get', day_file, null )
			// .catch( error => {

			// 	console.log( error )

			// 	// console.log( 'ERROR' )
			// 	// console.log( error )
			// 	// console.log( '------' )

			// 	// if ( error.response ) {
			// 	// 	console.log( 'client received an error response (5xx, 4xx)' )
			// 	// } else if ( error.request ) {
			// 	// 	console.log( 'client never received a response, or request never left' )
			// 	// } else {
			// 	// 	console.log( 'anything else' )
			// 	// }

			// })

	},

}