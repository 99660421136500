<template>

	<div id="day">

		<div id="top">

			<a href="/" id="logo"><img src="@/assets/img/logo-random-ttfl.png" /></a>

			<div class="crawling-notice" v-if="is_phone()">
				{{ crawling_notice }}
				<a v-if="is_phone()" class="btn" :href="'/team' + ( $route.params.date ? '/' + $route.params.date  : '' )">Team</a>
				<button v-if="is_phone()" @click="help_opened = !help_opened">?</button>
			</div>

			<container container_id="week"
				:collapse_on_mount="false"
				:classes="{ noheader : true }"
			>

				<template slot="container-body">

					<span class="crawling-notice" v-if="!is_phone()">{{ crawling_notice }}</span>

					<week :day="day" :base_url="'/'"></week>

					<nav id="menu" v-if="!is_phone()">
						<a class="btn" :href="'/team' + ( $route.params.date ? '/' + $route.params.date  : '' )">Team</a>
						<button @click="help_opened = !help_opened">{{ is_phone() ? '?' : 'Aide' }}</button>
					</nav>

				</template>

			</container>

		</div>

		<games :games="games"></games>

		<team ref="team_panel" :day="day"></team>

		<players :players="players" :day="day"></players>

		<box-help :opened="help_opened"></box-help>

	</div>

</template>

<script>

	// import api from '@/api'
	import api_days from '@/api_days'
	import mixins_global from '@/mixins/global'
	import mixins_tools from '@/mixins/tools'
	import device from '@/mixins/device'


	export default {

		name	: 'day',

		components	: {

			container	: () => import( '@/components/container.vue' ),
			boxHelp	: () => import( '@/views/day/box-help.vue' ),

			week	: () => import( '@/components/week.vue' ),
			games	: () => import( '@/views/day/games.vue' ),
			team	: () => import( '@/views/day/team.vue' ),
			players	: () => import( '@/views/day/players.vue' ),

		},

		data	: function() { return {

			datas_loaded	: 0,

			day	: false,
			days	: [],
			games	: [],
			players	: [],

			crawling_notice	: '',
			help_opened	: false,

		}},

		mixins	: [ mixins_global, mixins_tools, device ],

		methods	: {

			/**
			 * Récupération des données de la journée
			 */
			
			load_datas( attempts ) {

				var _this	= this

				if ( document.getElementById( 'logo' ) ) {
					document.getElementById( 'logo' ).classList.add( 'loading' )
				}

				this.loader( 'day', true )



				/**
				 * Random string afin de forcer l'actualisation du fichier
				 */

				// api_days.get( this.day.format( 'YYYY-MM-DD' ) + '.json?' + this.random_string( 10 ) )
				api_days.get( process.env.NODE_ENV == 'development' ? this.day.format( 'YYYY-MM-DD' ) + '.json?' + this.random_string( 10 ) : this.day.format( 'YYYY-MM-DD' ), )
				.then(( json ) => {

					var date_crawled	= _this.$moment( json.date_crawled )

					_this.games	= json.games
					_this.crawling_notice	= 'Le ' + date_crawled.format( 'DD MMMM à HH:mm' )

					if ( !json.injuries ) {

						json.injuries	= {
							players	: [],
						}

					}

					_this.$store.commit( 'players/set_players_from_api', {
						players	: json.players,
						games	: json.games,
						injuries	: json.injuries.players,
					})

				})
				.catch(function() {

					/**
					 * Essai répétés sans succès, on arrête tout
					 * 
					 * !! A FAIRE ==> AFFICHER UN MESSAGE
					 */

					if ( attempts == process.env.VUE_APP_API_ATTEMPTS ) {

						console.error( 'API ERROR : GET DAY DATAS' )
						return false

					}

					window.setTimeout(function() {
						_this.load_datas( attempts + 1 )
					}, process.env.VUE_APP_API_ATTEMPT_TIMEOUT )

				})

			},

			/**
			 */

			get_users_select() {

				var users	= this.$store.state.team.users
				var options	= [{
					label	: 'Je suis...',
					value	: 'null',
				}]

				users.forEach(function( user ) {
					options.push({
						label	: user.pseudo,
						value	: user.id,
					})
				})

				return options

			},

			/**
			 */

			change_user	: function( selected ) {

				if ( !selected ) {
					return false
				}

				var user_id	= selected.value

				if ( user_id == 'false' ) {

					this.$store.commit( 'user/update_user', {
						id  : false,
						picks : [],
						day : false,
					})

				} else {

					this.$store.dispatch( 'user/api_picks', user_id )

				}

			},

			/**
			 */

			all_datas_loaded	: function() {

				if ( this.users.length > 0 && this.players_from_db.length > 0 ) {
					
					if ( document.getElementById( 'logo' ) ) {
						document.getElementById( 'logo' ).classList.remove( 'loading' )
					}

					this.loader( 'day' )

				}

			},

		},

		computed	: {

			/**
			 */

			players_from_db() {
				return this.$store.state.players.results
			},

			/**
			 */

			users() {
				return this.$store.state.team.users
			},

		},

		watch	: {

			players_from_db() {
				this.all_datas_loaded()
			},

			users() {
				this.all_datas_loaded()
			},

		},

		mounted	: function() {
			
			var today_string	= this.$moment().format( 'YYYY-MM-DD' ) + ' 00:00:00'
			
			if ( typeof this.$route.params.date != 'undefined' ) {
				today_string	= this.$route.params.date + ' 00:00:00';
			}
			
			this.day	= this.$moment( today_string )

			this.load_datas( 0 )
			this.$store.dispatch( 'team/users_from_api', {
				attempts	: 0,
			})

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#top .container::v-deep {

		width: 100%;
		margin-top: 0;

		.container-body {

			@include flex_display;

			justify-content: space-between;
			flex-flow: row nowrap;

			@include mobile() {
				align-items: stretch;
			}
			
		}

		.crawling-notice {
			display: block;
			margin-right: 25px;
			font-size: 0.875em;
		}

		.container-body > div {
			flex: auto;
			max-width: 100%;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#top #user-select-mobile {

		min-width: 140px;
		border: 2px solid #000;

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#app.device-phone #week.container::v-deep > div {

		border: 0;
		background-color: transparent;

		.container-body {
			padding: 0;
		}

	}


</style>